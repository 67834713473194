import React from 'react'
import Layout from '../../containers/Layout'
import { Listing } from 'web-components'
import { imageCDNUrl } from '../../helpers'

import products from '../../config/text/products.json'

const baristaSupplies = products.baristaSupplies

class BaristaSupplies extends React.Component {
  render () {
    return (
      <Layout heroSrc='heros/latte-art-barista-supplies.jpg' heroText={baristaSupplies.title} title={baristaSupplies.meta.title}>
        <div className='baristaSupplies container'>
          <div className='row'>
            <div className='col-xs-12'>
              <p>{baristaSupplies.description}</p>
            </div>
          </div>
          <div className='row'>
            {baristaSupplies.products.map(product => {
              return <Listing
                key={product.name}
                description={product.description}
                footnote={product.footnote}
                halfWidth
                img={{
                  ...product.img,
                  src: imageCDNUrl(product.img.src)
                }}
                name={product.name}
                {...{ points: product.points }}
              />
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default BaristaSupplies
